<template>
  <div v-if="isOpen">
    <b-modal
      centered
      title="Config User Level"
      v-model="isOpen"
      id="modal-xl"
      size="lg"
      hide-footer
      @hide="cancel"
    >
      <b-card class="mt-1">
        <validation-observer ref="createForm" v-slot="{ handleSubmit }" class="mr-4 ml-4">
          <b-form @submit.stop.prevent="handleSubmit()">
            <div v-for="(level, index) in model.value" :key="index">
              <validation-provider
                name="Level name"
                v-slot="validationContext"
                :rules="{ required: true }"
              >
                <b-form-group
                  label="Level"
                  label-for="h-public-date"
                  label-cols-md="5"
                >
                  <b-form-input
                    v-model="level.level"
                    type="number"
                    class="border"
                  />
                  <b-form-invalid-feedback id="h-public-date">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                name="Level name"
                v-slot="validationContext"
              >
                <b-form-group
                  label="Name"
                  label-for="h-public-date"
                  label-cols-md="5"
                >
                  <ui-component
                    :component-data="level.name"
                    :is-input-field="true"
                    key-component="translatable-text-field"
                    class="border"
                  />
                  <b-form-invalid-feedback id="h-public-date">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <br>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
      <div class="text-center">
        <b-button
          variant="flat-success"
          class="btn-primary"
          size="sm"
          @click="addMoreLevel()"
        >+ Add more level</b-button>
      </div>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button :disabled="!!isLoading" type="submit" variant="primary" @click="onSave">
          <b-spinner v-if="isLoading" class="mr-1" small />
          <span>Save</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import service from "../../service";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import levelNameDefault from '@core/layouts/components/input-fields/translatable-text-field/defaultObject'
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    DatePicker
  },
  data() {
    return {
      isOpen: false,
      isUpdate: false,
      isLoading: false,
      model: {},
    };
  },

  async created() {
    // await this.getUserLevelsConfig()
  },

  methods: {
    async getUserLevelsConfig() {
      this.model = await service.getUserLevelsConfig()
      if (this.model.value && this.model.value.value) {
        this.model.value.value = new Date(this.model.value.value)
      }
    },

    addMoreLevel() {
      this.model.value.push({
        level: null,
        name: JSON.parse(JSON.stringify(levelNameDefault))
      })
    },

    async openCreateModal() {
      this.model = {}
      this.isOpen = true;
      this.isUpdate = false;
    },

    async openUpdateModal() {
      await this.getUserLevelsConfig()
      this.isOpen = true;
      this.isUpdate = true;
    },

    async onSave() {
      let validation = await this.$refs.createForm.validate();
      if (validation) {
        this.isOpen = true;
        this.isLoading = true;
        if (this.isUpdate) {
          try {
            await service.updateUserLevelsConfig(this.model);
          } catch (e) {
            this.isLoading = false;
          }
        }
        this.isLoading = false;
        this.isOpen = false;
        this.getUserLevelsConfig()
      }
    },

    cancel() {
      this.isOpen = false;
      this.isUpdate = false;
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  font-size: 16px;
  margin-top: 0.3rem;
  margin-bottom: 0;
}
</style>
